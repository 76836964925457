import "./Hero.scss";
import Typewriter from 'typewriter-effect';
import { Container } from "../../utils/Utils";
import Resume from '../../assets/pdf/mehrojbek-cv.pdf';
import { useEffect } from "react";
import { FaGithub, FaInstagram, FaLinkedin, FaTelegram } from 'react-icons/fa'
import {QRCodeCanvas}  from "qrcode.react";
import Aos from "aos";

const Hero = () => {

    useEffect(() => {
        Aos.init({once: true});
    }, []);

    return (
        <div className="hero">
            {/* <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>QR Code for my Portfolio</h1>
      <QRCodeCanvas
        value="https://mehrojbek.uz"
        size={236}
        accessKey="1q1q1q"
        bgColor={"#ffffff"}
        fgColor={"#000000"}
        level={"H"}
        includeMargin={true}
      />
      <p>Scan to visit: mehrojbek.uz</p>
    </div> */}
            <Container>
                <div className="hero-wrapper">
                    <div className="hero-content">
                        <h5 data-aos="fade-right" data-aos-duration="1000" data-aos-delay="100">Hey, I'm Aliyev Mehrojbek</h5>

                        {/* <p className="typewriter-text">
                            <Typewriter
                                options={{
                                    strings: [
                                        'Frontend Developer',
                                        'Software Engineer',
                                        'Web Developer and Freelancer',
                                    ],
                                    autoStart: true,
                                    loop: true,
                                    delay: 100,
                                    deleteSpeed: 50,
                                }}
                            />
                        </p> */}
                        <p >
                        A Result-Oriented Web Developer building and managing Websites and Web Applications that leads to the success of the overall product
                        </p>
                        <a data-aos="fade-right" data-aos-duration="1200" data-aos-delay="300" href={Resume} className="resume" download>Download Resume</a>

                        <div data-aos="fade-right" data-aos-duration="1200" data-aos-delay="900"     className="hero-socials">
                            <div className="socials">
                                <a className='social-link' href="https://github.com/mehrojalieev" target="_blank" rel="noreferrer"><FaGithub className='social-icon' /></a>
                                <a className='social-link' href="https://www.instagram.com/mehroj_alieev/" target="_blank" rel="noreferrer"><FaInstagram className='social-icon' /></a>
                                <a className='social-link' href="https://www.linkedin.com/in/mehrojbek-aliyev-805265284/" target="_blank" rel="noreferrer"><FaLinkedin className='social-icon' /></a>
                                <a className='social-link' href="https://t.me/mehroj220" target="_blank" rel="noreferrer"><FaTelegram className='social-icon' /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default Hero;
