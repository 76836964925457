import React from 'react'
import Contact from '../../components/contact/Contact'
import Projects from '../../components/projects/Projects'
import Hero from '../../components/hero/Hero'
import Skills from '../../components/skills/Skills'
import About from '../../components/about/About'

const Home = () => {
  return (
    <div>
      <Hero/>
      <About/>
      {/* <Skills/> */}
      <Projects/>
      <Contact/>
    </div>
  )
} 

export default Home
