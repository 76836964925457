import React, { useEffect } from 'react'
import "./About.scss"
import { Container } from '../../utils/Utils'
import Aos from 'aos'
import { Typewriter } from 'react-simple-typewriter'
import { FaGithub, FaInstagram, FaLinkedin, FaTelegram } from 'react-icons/fa'
import { TRUE } from 'sass'


const Skills = [
  "HTML",
  "CSS",
  "SASS",
  "Ant Design",
  "Material UI",
  "JavaScript",
  "Typescript",
  "React",
  "Redux",
  "Zustand",
  "Next.js",
  "Vue.js",
  "Pinia",
  "Maz-UI",
  "Rest-api",
  "PostgresSQL",
  "MongoDB",
  "Git & Github",
  "Figma",

]


const About = () => {
  useEffect(() => {
    Aos.init({
      once: true
    })
    console.log(true)
  }, [])
  return (
    <div id='about' className='about'>
      <Container>
        {/* <div className="about-wrapper">

        <div className="about-content">
          <h3 data-aos-once="true"  data-aos='fade-right' data-aos-duration='1000' data-aos-delay='100' className='about-title'>About Me</h3>
          <p data-aos-once="true" data-aos='fade-right' data-aos-duration='1000' data-aos-delay='400' className='about-text'>
            Hi, I'm Mehrojbek, a passionate Frontend Developer based in Tashkent, Uzbekistan. I specialize in creating visually stunning, responsive websites that help businesses and individuals establish a strong online presence.
            My journey into web development started with a curiosity about how websites work and a desire to create something impactful on the internet. Over the years, this curiosity has evolved into a full-fledged career where I get to combine my love for design and technology every day.
            I'm committed to continuous learning and staying up-to-date with the latest trends and technologies in frontend development to deliver the best results for my clients.
          </p>
        </div>
        <div className="socials-content">
          <h3 className='socials-title'>Socials</h3>
          <div className="socials">
            <a className='social-link' href="https://github.com/mehrojalieev" target="_blank" rel="noreferrer"><FaGithub className='social-icon' /></a>
            <a className='social-link' href="https://www.instagram.com/mehroj_alieev/" target="_blank" rel="noreferrer"><FaInstagram className='social-icon' /></a>
            <a className='social-link' href="https://www.linkedin.com/in/mehrojbek-aliyev-805265284/" target="_blank" rel="noreferrer"><FaLinkedin className='social-icon' /></a>
            <a className='social-link' href="https://t.me/mehroj220" target="_blank" rel="noreferrer"><FaTelegram className='social-icon' /></a>
          </div>
        </div>
         
        <div className="skills-content">
            <h3 className='skills-title'>Skills</h3>
            <div className="skills">
             {
              Skills.map((skill, index) => 
              <p key={index}>{skill}</p>
              )
             }
            </div>
        </div>

        <div className="education-content">
          <h3>Education</h3>
          <div className="content">
            <img src="https://is1-ssl.mzstatic.com/image/thumb/Purple125/v4/78/68/0c/78680cf1-2605-a625-9939-83d3f5316a07/source/512x512bb.jpg" alt="" />
          <p>Najot Ta'lim LC - <i>Frontend Bootcamp (React.js)</i></p>
          </div>
          <p className='text'>I graduated my programming course in the Najot ta'lim. I learned lots of programming languages(Javascript, Typescript, React.Js, Next.js, Astro.js and etc. ) and working with a team, collabrative with the clients during the study. Currently, I can make websites and web applications for people. Exp: Educational projects, E-commerce, Clothes shopping, Products shopping and others.</p>
        </div>
        </div> */}

        {/* NEW ABOUT */}

        <div className="about-wrapper">
          <header className='about-header'>
            <h2>ABOUT ME</h2>
            <div className='header-line'></div>
            <p className='header-text'>Here you will find more information about me, what I do, and my current skills mostly in terms of programming and technology</p>
          </header>

          <div className="about__content-wrapper">
            <div className="about__content">
              <h4>Get to know me !</h4>
              <p>
                I'm a Frontend Focused Web Developer building and managing the Front-end of Websites and Web Applications that leads to the success of the overall product. Check out some of my work in the Projects section.
              </p>
              <p>
                I also like sharing content related to the stuff that I have learned over the years in Web Development so it can help other people of the Dev Community. Feel free to Connect or Follow me on my Linkedin and Instagram where I post useful content related to Web Development and Programming
              </p>
              <p>
                I'm open to Job opportunities where I can contribute, learn and grow. If you have a good opportunity that matches my skills and experience then don't hesitate to contact me.
              </p>
              <a className='contact-link' href="#contact">Contact</a>
            </div>
            <div className="skills__content">
              <h4>My Skills</h4>
              <div className="skills">
                {
                  Skills.map((skill, index) => 
                  <p key={index}>{skill}</p>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default About
