import "./Nav.scss"
import { useEffect, useLayoutEffect, useState } from 'react'
import { Container } from '../../utils/Utils'
import { Link } from "react-router-dom"
import { IoMdMenu } from "react-icons/io";

const Nav = () => {
  
    const [scrollaction, setScrollAction] = useState(0)
    const [openMenu, setOpenMenu] = useState(false)
    const ScrollUpdate = ()  => {
        setScrollAction(window.scrollY)
    }   
    
    useEffect(() => {
            window.addEventListener('scroll', ScrollUpdate)
            return () => {
                window.removeEventListener('scroll', ScrollUpdate)
            }
    }, [])

  return (
    <nav className={ scrollaction > 174 ? " shadow-nav" : "" } >
      <Container>
            <div className="nav-wrapper">
                <Link onClick={() => window.scrollTo(0,0)} to={"/"} className="nav__authour-title">
                    <h1> Mehrojbek</h1>
                </Link>
                <ul style={openMenu ? {display: "flex"} : {}} className="nav__menu">
                        <li><Link onClick={() => window.scrollTo(0,0)} className='item-link' to={'/'}>Home</Link></li>
                        <li><a className='item-link' href="#about">About</a></li>
                        {/* <li><a className='item-link' href="#skills">Skills</a></li> */}
                        <li><a className='item-link' href="#projects">Portfolio</a></li>
                        <li><a className='item-link' href="#contact">Contact</a></li>
                </ul>
                <div className="responsive__menu-wrapper">
                    <ul className="responsive__menu">
                        <li><a className='item-link' href="#about">About</a></li>
                        <li><a className='item-link' href="#skills">Skills</a></li>
                        <li><a className='item-link' href="#projects">Portfolio</a></li>
                        <li><a className='item-link' href="#contact">Contact</a></li>
                    </ul>
                </div>
                {
                    openMenu ? <span onClick={() => setOpenMenu(false)}   className='material-symbols-outlined menu-btn'>close</span>
                    : <span onClick={() => setOpenMenu(true)}   className=' menu-btn'><IoMdMenu/></span>
                }
            </div>
      </Container>
    </nav>
  )
}

export default Nav
