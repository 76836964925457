import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "./IpData.scss";
const IpData = () => {
  const [ipData, setIpData] = useState([]);

  console.log(ipData);
  

  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await axios.get('https://ip-detector.onrender.com/api/visitors');
        setIpData(response.data);
        console.log(response.data);
        
      } catch (error) {
        console.log(error);
      }
    };

    fetchIpAddress();
  }, []);

  return (
    <div className="ip-content">
      <h2>Visitor IP Addresses:</h2>
      <table>
        <thead>
          <tr>
            <th>IP Address</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {ipData.length > 0 ? ipData.map((ip, index) => (
            <tr key={index}>
              <td>{ip.ip_address}</td>
              <td>{ip.date}</td>
            </tr>
          )) : (
            <tr>
              <td colSpan="2">IP Addresses not found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default IpData;
