import Aos from 'aos'
import "./Projects.scss"
import { Link } from 'react-router-dom'
import React, { useEffect } from 'react'
import { Container } from "../../utils/Utils"
import ProjectsData from "../../db/projects.json"
import { MdOutlineArrowOutward } from "react-icons/md"

const Projects = () => {

    useEffect(() => {
        Aos.init({once: true})
        
    }, [])


    return (
        <>
        <div id='projects' className='projects'>
            
            <Container>
                <h2 data-aos-once='true' data-aos='fade-up' data-aos-duration='1000' className='portfolio-title'>MY PROJECTS</h2>
                <div className="projects__card-wrapper">
                    {
                        ProjectsData.map((project, key) => {
                            const image = require(`../../assets/images/${project.image}`)
                            return (
                                <div data-aos={project['aos-type']} data-aos-duration={project['aos-duration']} data-aos-delay={project['aos-delay']} data-aos-once={true} className="project-card" key={key}>
                                    <h3 className='title'>{project.title}</h3>
                                    <p className='description'>{project.description.slice(0, 153)}...</p>
                                        <img src={image} alt={project.title} />
                                        <h5 className='skills-title'>SKILLS</h5>

                                        <div className="project-skills">
                                            {
                                                project.skills.map((skill, index) => <p key={index}># {skill}</p>)
                                            }
                                        </div>
                                        <div className="card-btns">
                                            <a href={project.link} target="_blank" rel="noreferrer" className='btn demo-link'> Demo <MdOutlineArrowOutward className='btn-icon' /></a>
                                            <Link to={`/projects/${project.id}`} className='btn detail-link'>Details <MdOutlineArrowOutward className='btn-icon' /></Link>
                                        </div>
                                </div>
                            )
                        })
                    }
                </div>
            </Container>
        </div>
      </>
    )
}

export default Projects
